<template>
  <v-container id="user" fluid tag="section">
    <v-snackbar v-model="updateRegisterTemplate" :timeout="4000" top>
      <span>Register Customer Email Templates Update Successfully</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="updateRegisterTemplate = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
    <v-card>
      <v-card-title> <h3>Register Customer Email Templates</h3></v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-form ref="registerEmail">
              <v-container class="py-2">
                <vue-editor v-model="registerEmailTemplate"></vue-editor>
                <p style="color: red" v-if="showError">
                  Register Customer Email Templates is required
                </p>
                <v-row>
                  <v-col cols="12" class="text-right">
                    <v-btn
                      type="button"
                      color="primary"
                      class="mt-3"
                      @click="submitRegisterEmail"
                      :loading="loading"
                      >Update
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <RegisterDriverEmail />
    <ResetPasswordEmail />
    <OrderPurchaseEmail />
  </v-container>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
    RegisterDriverEmail: () => import("./RegisterDriverEmail"),
    ResetPasswordEmail: () => import("./ResetPasswordEmail"),
    OrderPurchaseEmail: () => import("./OrderPurchaseEmail"),
  },
  data() {
    return {
      loading: false,
      registerEmailID: "",
      registerEmailTemplate: "",
      resetPasswordEmailTemplate: "",
      showError: false,
      updateRegisterTemplate: false,
      showResetPasswordError: false,
      loading: false,
    };
  },
  methods: {
    getRegisterEmail() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "setting/edit/register_customer_email_template"
        )
        .then((response) => {
          if (response.status == 200) {
            this.registerEmailID = response.data.setting._id;
            this.registerEmailTemplate = response.data.setting.value;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitRegisterEmail() {
      if (this.registerEmailTemplate != "") {
        this.showError = false;
        this.loading = true;
        let data = {
          id: this.registerEmailID,
          type: "register_customer_email_template",
          value: this.registerEmailTemplate,
        };
        axios
          .post(process.env.VUE_APP_API_BASE_URL + "setting/update", data)
          .then((response) => {
            if (response.status == 200) {
              this.loading = false;
              this.updateRegisterTemplate = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.showError = true;
      }
    },
  },
  mounted() {
    this.getRegisterEmail();
  },
};
</script>

<style>
</style>